<template>
    <div class="login">
        <Top name="账号登录" back></Top>
        <div class="login_w">
            <div class="login_wt">
                <div :class="{active: isActive}" @click="isActive = true; changeForm()">
                    <svg t="1622708778831" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2334" width="18" height="18"><path d="M252.7 461.3h518.7c28.6 0 51.9 23.2 51.9 51.9v363.1c0 28.6-23.2 51.9-51.9 51.9H252.7c-28.6 0-51.9-23.2-51.9-51.9V513.2c0.1-28.6 23.3-51.9 51.9-51.9z m259.4 128c-43 0-77.8 32.5-77.8 72.6 0 26.8 15.8 50 38.9 62.5v69.1c0.3 21.2 17.7 38.2 38.9 37.9 21.2 0.3 38.6-16.7 38.9-37.9v-69.1c23.1-12.6 38.9-35.7 38.9-62.5 0-40.1-34.8-72.6-77.8-72.6z m155.8-273.2h-0.2c0-80.2-69.7-145.2-155.6-145.2s-155.6 65-155.6 145.2h-0.1v145.2H279V316.1h-0.3c0-120.3 104.5-217.9 233.4-217.9 128 0 231.9 96.1 233.4 215.3h0.2v147.8h-77.8V316.1z" p-id="2335" fill="#ffffff"></path></svg>
                    <span>密码登录</span>
                </div>
                <div :class="{active: !isActive}" @click="isActive = false; changeForm()">
                    <svg t="1622708823301" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3400" width="18" height="18"><path d="M735.053 2.183H288.945c-52.65 0-95.595 43.002-95.595 95.59v828.453c0 52.588 42.945 95.59 95.595 95.59h446.108c52.524 0 95.595-43.002 95.595-95.59V97.774c0-52.589-43.071-95.591-95.595-95.591zM384.54 49.978h254.92v31.864H384.54V49.978z m127.458 908.11c-35.222 0-63.729-28.563-63.729-63.726 0-35.161 28.506-63.726 63.73-63.726 35.1 0 63.73 28.565 63.73 63.726 0 35.163-28.63 63.727-63.73 63.727z m254.92-191.18H257.08v-637.27H766.92v637.27z" p-id="3401" fill="#ffffff"></path></svg>
                    <span>短信登录</span>
                </div>
            </div>
            <div class="login_form">
                <div class="form_item">
                    <input type="text" v-model="loginForm.phone" oninput = "value=value.replace(/[^\d]/g,'')" placeholder="手机">
                    <span>+86</span>
                </div>
                <div class="form_item" v-show="isActive">
                    <input type="password" v-model="loginForm.password" placeholder="请输入登录密码">
                </div>
                <div class="form_item" v-show="!isActive">
                    <input type="text" v-model="loginForm.yzm" oninput = "value=value.replace(/[^\d]/g,'')" placeholder="请输入手机验证码">
                    <GetYzmCode :username="loginForm.phone"></GetYzmCode>
                </div>
            </div>
            <div class="dragverify">
                <DragVerify
                    v-if="isShow"
                    ref="dragVerify"
                    :isPassing.sync="isPassing2"
                    text="请按住滑块拖动"
                    successText="验证通过"
                    handlerIcon="drag_arrow"
                    successIcon="van-icon van-icon-success"
                    handlerBg="#fff"
                    >
                </DragVerify>
            </div>
            <div class="login_notice">
                <span>账号说明</span>
                <router-link to="accountdesp" class="accountdesp"><van-icon name="question-o" size="1.4rem" /></router-link>
                <router-link to="findpassword">忘记密码</router-link>
            </div>
            <div class="login_btns">
                <van-button round type="info" size="large" color="linear-gradient(to right, #FF704D, #F73727)" @click="loginClick">登录</van-button>
                <router-link to="register">
                    <van-button plain round type="info" size="large" class="reg_btn">注册</van-button>
                </router-link>
            </div>
            <div class="beian">
                <a href="javascript:;">网络文化经营许可证：闽网文[2017]3886-063号</a><br>
                <a href="https://beian.miit.gov.cn/#/Integrated/index">闽ICP备16031308号-13</a> <i class="i_img"></i><a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=35020302033952">公安备案号：35020302034012</a><br>
                <a href="javascript:;">增值电信业务经营许可证：闽B2-20170176</a>
            </div>
        </div>
    </div>
</template>
<script>
import Top from '@/components/top'
import DragVerify from "@/components/dragVerify"
import GetYzmCode from '@/components/getYzmCode'

export default {
    components: { 
        Top,
        DragVerify,
        GetYzmCode
    },
    name: 'Login',
    data() {
        return {
            isActive: true,
            isPassing2: false,
            loginForm: {
                phone: '',
                password: '',
                yzm: ''
            },
            isShow: true,
            yzmCode: '',
            docmHeight: '0', //初始状态可视区高度
            showHeight: '0', //实时可视区高度
            hidshow: true, //是否显示底部
            isAndroid: false, //是否为安卓系统
        }
    },
    created() {},
    beforeRouteEnter(to, from, next) {
        let titleData=sessionStorage.getItem('title')
        if(titleData){
            next((vm) => {
                // console.log("vm", vm.$route.query.a);
                let arr = vm.$Base64.decode(titleData)
                arr = JSON.parse(arr)
                sessionStorage.removeItem('title')
                vm.$api.login.loginSdk(arr).then(res => {
                    if (res.code == 200) {
                        // console.log(vm.$route.query.a)
                        localStorage.setItem('token', 'Bearer '+ res.data.token);
                        localStorage.setItem('username', res.data.username);
                        if (arr.seedName) {
                          arr.seedname = arr.seedName;
                          delete arr.seedName
                        }
                        // list = JSON.stringify({password: 'M2QwZm1abnZmVVhJczMrZU5OK2xSUEFXMlFpTWVFZEtBamRNME…VIzZHZHYTMxTjA0K2dha2tYNjE0TVBiYWxHMnZuRWE2ZTI3Zw', server_id: '50001', type: '24', username: '3143635', seedname: '50001166441587511921',onsale_type:'1'})
                        sessionStorage.setItem('a', JSON.stringify(arr))
                        sessionStorage.setItem('type', arr.type);
                        // const path = window.location.href.split('?')[0]
                        window.location.href=sessionStorage.getItem('comePath')
                    }
                })
            });
        }else{
            next();
        }
    },
    methods: {
        //切换
        changeForm() {
            this.loginForm.phone = '';
            this.loginForm.password = '';
            this.loginForm.yzm = '';
            this.isShow = false;
            setTimeout(() => {
                this.$nextTick(() => {
                    this.isShow = true;
                    this.isPassing2 = false;
                });
            }, 100);
        },
        //登录请求
        loginRequest() {
            let param = {};
            param = this.isActive ? { username: this.loginForm.phone, password: this.loginForm.password } : { username: this.loginForm.phone, code: this.loginForm.yzm };
            if (this.isActive) {
                this.$api.login.loginIn(param).then(res => {
                    if (res.code == 200) {
                        localStorage.setItem('token', 'Bearer '+ res.data.token);
                        localStorage.setItem('username', this.loginForm.phone);
                        this.$router.push('/');
                    }
                }).catch(err => {
                    console.log(err);
                });
            }else{
                //验证验证码
                this.$api.register.checkCode({
                    username: this.loginForm.phone,
                    code: this.loginForm.yzm
                }).then(res => {
                    if (res.code == 200) {
                        //登录请求
                        this.$api.login.loginByCode(param).then(res => {
                            if (res.code == 200) {
                                localStorage.setItem('token', 'Bearer '+ res.data.token);
                                localStorage.setItem('username', this.loginForm.phone);
                                this.$router.push('/');
                            }
                        }).catch(err => {
                            console.log(err);
                        });
                    }else{
                        this.$toast({
                            message: '验证码错误！',
                            duration: 1500
                        });
                    }
                }).catch(err => {
                    console.log(err);
                });
            }
            
        },
        //登录
        loginClick() {
            if (this.isPassing2) {
                if (this.isActive) {
                    //密码登录
                    if (this.loginForm.phone != '' && this.loginForm.password != '') {
                        this.loginRequest();
                    }else{
                        this.$toast({
                            duration: 1500,
                            message: '请先填完输入框'
                        });
                    }
                }else{
                    //短信登录
                    if (this.loginForm.phone != '' && this.loginForm.yzm != '') {
                        this.loginRequest();
                    }else{
                        this.$toast({
                            duration: 1500,
                            message: '请先填完输入框'
                        });
                    }
                }
            }else{
                this.$toast({
                    duration: 1500,
                    message: '请先拖动滑块'
                });
            }
        },
    }
}
</script>
<style scoped>
.beian{
    /* position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50% , -50%);
    width: 306px; */
    margin-top: 41px;
}
.i_img{
    display: inline-block;
    width: 21px;
    height: 21px;
    background: url('../assets/images/icons.png') no-repeat;
    background-size: 100% 100%;
    vertical-align: middle;   
}
</style>
<style>
.login_notice .accountdesp{
    float: left;
    color: #313131;
}
html{
touch-action:none;
touch-action:pan-y;
}
</style>